.footer-banner {
  background: url('./../../assets/images/register-bg.png') no-repeat;
  background-size: 100% 100%;
  text-align: center;
  color: white;
  padding: 2.8rem 0;
}

.footer-link {
  font-weight: 400;
  font-size: 14px;
  color: #7b7382;
  padding: 3px 0;
  display: block;
}

.footer-heading {
  font-weight: 400;
  font-size: 14.4px;
  line-height: 17px;
  color: #934ece;
  margin: 0 0 1.6rem;
}
.socialLink {
  border: 1px solid #d4d4d8;
  border-radius: 50%;
  width: 28px;
  height: 28px;
  display: grid;
  justify-content: center;
  align-content: center;
}
.socialLink:hover {
  background-color: #934ece;
}
.iconMargin {
  margin-top: 8px !important;
}
